.aboutmain {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  padding: 37px;
  text-align: center;
  padding: 2rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
  border: 3px solid #f1f1f1;
}

.imagestyle{
  border-radius: 10%;
}


.paragraph {
  margin-top: 110px;
  font-size: 20px;
  padding-right: 80px;
}

@media (max-width: 600px) {
  .column {
    float: none;
    width:100%;
  }
  .paragraph {
    padding-right: 0px;
    padding-left: 10px;
  }
}
