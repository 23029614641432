.main  {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  width: 100%
}
.column {
  float: left;
  width: 10%;
  padding: 23px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.iconStyle {
    font-size: 50px;
    color: black;
}
