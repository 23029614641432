.navbar{
  position:fixed;
  top:0;
  z-index:2;
  background-color: #333;
  width: 100%;
}

.uldesign {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.lidesign {
  float: left;
}

.lidesign{
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.lidesign:hover {
  background-color: #111;
  cursor: pointer;
}

.lidesign > .active {
    border-bottom: 1px solid white;
}


@media (max-width: 700px) {
  .lidesign {
    padding-left: 14px;
    padding-right: 1px;
  }
  .uldesign{
    padding-left: 6px;
  }
}
