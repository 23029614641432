.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 30px;
  align-items: stretch;
  padding-left: 50px;
  padding-right: 50px;

}

.grid > article {
  border-radius: 20px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.grid > article img {
  max-width: 100%;
  border-radius: 10px;
}

.grid .text {
  padding: 20px;
}

.links{
  color: grey;
  text-decoration: none;
}

.links:hover {
  color: black;
  border-bottom: 3px solid #373737;
}
